.book-box {
  font-family: "Nunito", sans-serif;
  height: 20rem;
  padding: 2px;
  background-color: #21242b;
  overflow: hidden;
  border-radius: 0.375rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: 1px solid #21242b;
  transition: border-color 0.2s ease-in-out;
}
.book-box:hover {
  background-color: #282c34;
  border: 1px solid #282c34;
}
.parent {
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 1fr 2fr;
  grid-column-gap: 15px;
  grid-row-gap: 0px;
}

.title {
  grid-area: 1 / 2 / 2 / 3;
}
.image {
  grid-area: 1 / 1 / 3 / 2;
}
.desc {
  grid-area: 2 / 2 / 4 / 3;
}
