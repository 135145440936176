.App {
  font-family: "Nunito", sans-serif;
  text-align: center;
}

body {
  background-color: #282c34;
  color: white;
  overflow-y: hidden;
}

input[type="text"] {
  background-color: #4a515f;
  border: none;
  color: white;
  padding: 0.5rem;
  border-radius: 0.5em;
  border: 1px solid #21242b;
}

li {
  text-align: left;
  list-style: none;
}

ul {
  grid-gap: 4px;
  padding: 1vw;
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

@media (min-width: 992px) {
  ul {
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }
}
a,
a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  color: white;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

button {
  background-color: #21242b;
  border: 1px solid #21242b;
  border-radius: 0.375rem;
  margin: 0.5rem;
  padding: 0.5rem;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  font-size: unset;
}

button:hover {
  background-color: #282c34;
}

button:disabled {
  background-color: #21242b;
  color: #282c34;
  cursor: not-allowed;
}

.nbLivres {
  margin: 0.5rem;
}
