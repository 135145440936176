.head {
  font-family: "Nunito", sans-serif;
  background-color: #21242b;
  text-align: center;
  border-radius: 0.8rem;
  margin: 0.2rem 1rem 1rem 1rem;
}

.head > * {
  margin-block-start: 0em;
  margin-block-end: 0em;
}

h1 {
  padding-top: 0.3rem;
}
